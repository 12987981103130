@import "~bootstrap/scss/bootstrap";
@import "./styles/fonts";
@import "./styles/variables";

body {
  margin: 0;
}

::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: $color-secondary-color;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: $color-secondary-color;
}
