/* Global */

$color-border-form: #8a8a8a; // !
$color-gray-light: #bcbcbc;
$color-gray-lighter: #efefef;
$color-links-and-hover: #00615c;
$color-menu-background: #d9e4e4;
$color-secondary-color: #009b96;
$color-triplea-grey: #575757;

$focus-outline-color: #009b96;
$focus-outline-offset: 2px;
$focus-outline-width: 2px;

$font-bread-regular-family: 'OpenSans-Regular', 'open sans', arial, helvetica, sans-serif;
$font-bread-semibold-family: 'OpenSans-SemiBold', 'open sans', arial, helvetica, sans-serif;
$font-header-family: 'PalatinoLTStd-Medium', Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif;
$font-header-italic-family: 'PalatinoLTStd-Italic', Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif;
